<template>
	<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 left_side_bar e_chart_sidebar px-0" v-once>
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li :class="{ 'active': this.$route.name === 'InvoiceMasterDashboard' }" >
                    <router-link :to="{ name: 'InvoiceMasterDashboard'}"> Dashboard</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'InvoiceMasterDashboard'">
				</li>
                <li :class="{ 'active': this.$route.name === 'InvoiceMasterCreate' }" >
                    <router-link :to="{ name: 'InvoiceMasterCreate'}"> Create Invoice</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'InvoiceMasterCreate'">
				</li>
				<li :class="{ 'active': this.$route.name === 'InvoiceList' }" >
                    <router-link :to="{ name: 'InvoiceList'}"> Manage Invoice</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'InvoiceList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'InvoiceAnalysisReport' }" >
                    <router-link :to="{ name: 'InvoiceAnalysisReport'}"> Analysis Reports</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'InvoiceAnalysisReport'">
				</li>
            </ul>
        </div>
    </div>
</template>